"use client";
import React, { useState } from "react";

type FormValues = {
  nom: string;
  prenom: string;
  email: string;
  country: string;
};

const FormResult = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    nom: "",
    prenom: "",
    email: "",
    country: "",
  });
  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validateName = (name: string): string | null => {
    if (!name) return "Name is required.";
    if (!/^[a-zA-Z]+$/.test(name)) return "Name must contain only letters.";
    return null;
  };

  const validateEmail = (email: string): string | null => {
    if (!email) return "Email is required.";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return "Invalid email format.";
    return null;
  };

  const sanitizeInput = (input: string): string => {
    return input.replace(/[<>/\\]/g, "");
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: sanitizeInput(value) });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors: Partial<FormValues> = {};
    const nomError = validateName(formValues.nom);
    const prenomError = validateName(formValues.prenom);
    const emailError = validateEmail(formValues.email);

    if (nomError) newErrors.nom = nomError;
    if (prenomError) newErrors.prenom = prenomError;
    if (emailError) newErrors.email = emailError;

    setErrors(newErrors);

    if (!nomError && !prenomError && !emailError) {
      try {
        const sessionId = localStorage.getItem("quiz_session_id");

        const response = await fetch("/api/updateUserInfo", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: sessionId,
            quiz_code: "CIG_SIMP",
            name: formValues.nom,
            surname: formValues.prenom,
            email: formValues.email,
          }),
        });

        if (response.ok) {
          console.log("User information updated");
        } else {
          console.error("Failed to update user information");
        }
      } catch (error) {
        console.error("Error submitting form", error);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-transparent w-full md:w-2/3 flex flex-col justify-center items-center gap-4"
    >
      <div className="flex justify-center items-center gap-4 flex-row w-full">
        <div className="w-full">
          <label className="block text-gray-700">Nom</label>
          <input
            type="text"
            name="nom"
            value={formValues.nom}
            onChange={handleChange}
            className={`w-full px-3 py-2 border ${
              errors.nom ? "border-red-500" : "border-gray-300"
            } rounded`}
          />
          {errors.nom && <p className="text-red-500 text-sm">{errors.nom}</p>}
        </div>

        <div className="w-full">
          <label className="block text-gray-700">Prénom</label>
          <input
            type="text"
            name="prenom"
            value={formValues.prenom}
            onChange={handleChange}
            className={`w-full px-3 py-2 border ${
              errors.prenom ? "border-red-500" : "border-gray-300"
            } rounded`}
          />
          {errors.prenom && (
            <p className="text-red-500 text-sm">{errors.prenom}</p>
          )}
        </div>
      </div>

      <div className="w-full">
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
          className={`w-full px-3 py-2 border ${
            errors.email ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
      </div>

      <div className="overflow-hidden rounded-lg shadow max-w-[16rem]">
        <button
          type="submit"
          className="w-full flex items-center justify-center px-8 py-2 text-base font-medium text-white transition-colors duration-150 bg-[#111213]"
        >
          Obtenir le rapport
        </button>
      </div>
    </form>
  );
};

export default FormResult;
