"use client";
import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { Logo } from "@/public";

export default function nav_1() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="bg-transparent pt-0 md:pt-4 md:top-6 absolute top-0 left-0 w-full z-20">
      <nav className="relative md:mx-16 mx-8 flex justify-between items-center">
        <Link
          className="text-3xl font-bold leading-none flex flex-row justify-center items-center"
          href="/"
          aria-label="Homepage"
        >
          <Image alt="logo iovia" className="h-16 w-16" src={Logo} />
          IOVIA
        </Link>
        <button
          className={`lg:hidden navbar-burger items-center text-black p-3  ${
            menuOpen ? "hidden" : "flex"
          }`}
          aria-label="Toggle menu"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <svg
            className="block h-6 w-6 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Mobile menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
        <ul
          className={`hidden lg:flex lg:items-center lg:w-auto lg:space-x-6 lg:ml-16 lg:gap-8  ${
            menuOpen ? "" : "hidden"
          }`}
        >
          <li>
            <Link
              className="text-sm font-bold text-black hover:text-gray-600"
              href="/quiz"
            >
              Faire le questionnaire
            </Link>
          </li>
          <li>
            <Link
              className="text-sm font-bold text-black hover:text-gray-600"
              href="/iovia"
            >
              Télécharger Iovia
            </Link>
          </li>
          <li>
            <Link
              href="/faq"
              className="text-sm font-bold text-black hover:text-gray-600"
            >
              FAQ
            </Link>
          </li>
        </ul>
        <Link
          className="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-black text-sm text-white hover:text-[#EBA41B] font-bold rounded-lg transition duration-200"
          href="/quiz"
        >
          Faire le questionnaire
        </Link>
      </nav>
      {menuOpen && (
        <div className="navbar-menu relative z-50">
          <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
          <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <Link
                className="mr-auto text-3xl font-bold leading-none  flex flex-row justify-center items-center"
                href="/"
                aria-label="Homepage"
              >
                <Image alt="logo iovia" className="h-16 w-16" src={Logo} />{" "}
                IOVIA
              </Link>
              <button
                className="navbar-close"
                aria-label="Close menu"
                onClick={() => setMenuOpen(false)}
              >
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                    href="/quiz"
                    onClick={() => setMenuOpen(false)}
                  >
                    Faire le questionnaire
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                    href="/iovia"
                    onClick={() => setMenuOpen(false)}
                  >
                    Télécharger IOVIA
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                    href="/faq"
                    onClick={() => setMenuOpen(false)}
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
}
